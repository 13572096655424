var SameAddressCheckbox = {};

SameAddressCheckbox.items = [];

SameAddressCheckbox.add = function (checkboxSelector, source, destination) {
    var checkboxItem = {};
    checkboxItem.selector = checkboxSelector;
    checkboxItem.element = $(SameAddressCheckbox.selector);
    checkboxItem.source = source;
    checkboxItem.destination = destination;
    SameAddressCheckbox.listenForCheck(checkboxItem);
    SameAddressCheckbox.items.push(checkboxItem);
};

SameAddressCheckbox.listenForCheck = function(checkboxItem){
    console.log(checkboxItem.selector);
    $(checkboxItem.selector).change(function(){
        if ($(this).is(':checked')) {
            SameAddressCheckbox.populate(checkboxItem.source, checkboxItem.destination);
        }
    });
};

SameAddressCheckbox.populate = function(source, destination){
    var addressFields = ['street','city','state','zipcode'];
    for (var i=0;i<addressFields.length;i++){
        var fieldName = addressFields[i];
        var sourceSelector = '[name="'+source+'['+fieldName+']"]';
        var destinationSelector = '[name="'+destination+'['+fieldName+']"]';
        if ( $( sourceSelector ).length && $( destinationSelector ).length ) {
            $( destinationSelector ).val($( sourceSelector ).val());
        }
    }
};