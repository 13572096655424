var GeneratePasswordButton = {};

GeneratePasswordButton.items = [];

GeneratePasswordButton.add = function (selector) {
    var item = {};
    item.selector = selector;
    item.element = $(item.selector);
    item.target = item.element.data('target');
    GeneratePasswordButton.listenForClick(item);
    GeneratePasswordButton.items.push(item);
};

GeneratePasswordButton.listenForClick = function(item){
    $(item.selector).click(function(){
        var password = GeneratePasswordButton.generatePassword();
        $(item.target).val(password);
    });
};

GeneratePasswordButton.generatePassword = function (){
    var password = Math.random().toString(36).slice(-10);
    console.log(password);
    return password;
}